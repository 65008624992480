// @codekit-prepend "../../node_modules/js-cookie/dist/js.cookie.min.js"
// @codekit-prepend "../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js"
// @codekit-prepend "../../node_modules/slick-carousel/slick/slick.min.js"
// @codekit-prepend "../../node_modules/@vimeo/player/dist/player.min.js"

(function ($) {
	/* Breakpoints */
	var breakpoints = {
		large: 1200,
		medium: 992,
		small: 768,
		xsmall: 767,
		nav: 992
	};

	/* Check window width */
	var resizeTimeout = null;
	var cachedWindowWidth = $(window).width();

	// Check if window width has actually changed
	function checkWindowWidth() {
		var newWindowWidth = $(window).width();

		if (newWindowWidth !== cachedWindowWidth) {
			// If changed call functions on resize
			cachedWindowWidth = newWindowWidth;
		}
	}
	$(window).on("resize", function () {
		if (resizeTimeout !== null) {
			clearTimeout(resizeTimeout);
		}

		resizeTimeout = setTimeout(checkWindowWidth, 50);
	});

	/* Fix ADA errors/issues */
	// function adaFixes() {

	// }
	// adaFixes();

	/* Format phone numbers */
	// Strip non-numeric characters from phone numbers and add to href if element contains `tel` class
	function formatPhoneNumbers() {
		var phoneNumber;
		var phoneNumbers = $(".tel");
		var extension = $(".tel + .ext");

		phoneNumbers.each(function () {
			var $this;

			// Remove '1-' prefix before formatting if it exists
			if ($(this).text().substr(0, 2) === "1-") {
				$this = $(this).text().replace("1-", "");
			} else {
				$this = $(this).text();
			}

			// Set phone number, considering extension if it exists
			if (extension.length) {
				phoneNumber =
					"tel:1" +
					$this.replace(/\D/g, "") +
					";ext=" +
					extension.data("ext");
			} else {
				phoneNumber = "tel:1" + $this.replace(/\D/g, "");
			}

			$(this).attr("href", phoneNumber);
		});
	}
	formatPhoneNumbers();

	/* Format email addresses */
	// Add email addresses to href if element contains `eml` class
	function formatEmailAddresses() {
		var emailAddress;
		var emailAddresses = $(".eml");

		emailAddresses.each(function () {
			emailAddress = "mailto:" + $(this).text();
			$(this).attr("href", emailAddress);
		});
	}
	formatEmailAddresses();

	/* Header */
	/* Sticky Navigation */
	function stickyNavigation() {
		var didScroll;
		var lastScrollTop = 0;
		var delta = 5;
		var fullHeader = $(".site-header");
		var fullHeaderClasses = fullHeader.attr("class");
		var mainHeader = $(".site-header > .main");
		// var mainHeaderClasses = mainHeader.attr('class');
		var fullHeaderHeight = fullHeader.outerHeight();
		var mainHeaderHeight = mainHeader.outerHeight();
		var topHeaderHeight = $(".site-header > .top").outerHeight();

		// Add padding to entry header so the header doesn't cover it
		var mainSiteEntryHeader = $(".site-main > .entry-header");
		mainSiteEntryHeader.css("padding-top", fullHeaderHeight + "px");

		// Detect scroll
		$(window).scroll(function (e) {
			didScroll = true;
		});

		setInterval(function () {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 100);

		function hasScrolled() {
			var st = $(this).scrollTop();

			// Make sure they scroll more than delta
			if (Math.abs(lastScrollTop - st) <= delta) {
				return;
			}

			// If they scrolled down and are past the navbar, add class .nav-up
			// This is necessary so you never see what is "behind" the navbar
			if (st > lastScrollTop && st > mainHeaderHeight) {
				// Scroll Down
				// fullHeader.removeClass('scroll-up');
				// fullHeader.addClass('scroll-down');
				fullHeader.addClass("sticky");
				$("body").addClass("sticky-active");
				fullHeader.css({
					transform: "translateY(-" + (fullHeaderHeight + 12) + "px)"
				});
			} else if (st < delta) {
				// Back at top
				fullHeader.removeClass("sticky");
				fullHeader.addClass(fullHeaderClasses);
				fullHeader.css({ transform: "translateY(0)" });
			} else {
				// Scroll Up
				if (
					st < lastScrollTop &&
					st + $(window).height() < $(document).height() &&
					cachedWindowWidth >= breakpoints.nav
				) {
					fullHeader.css({
						transform:
							"translateY(-" + (topHeaderHeight - 6) + "px)"
					});
				} else {
					fullHeader.css({
						transform: "translateY(-" + topHeaderHeight + "px)"
					});
				}
			}

			lastScrollTop = st;
		}
	}
	if (!$(".site-header").hasClass("not-sticky")) {
		stickyNavigation();
	}

	/* Desktop Navigation */
	// Handle large devices that show the desktop menu and need touch support as hover doesn't work (iPad Pro for example)
	function isTouchDevice() {
		return typeof window.ontouchstart !== "undefined";
	}

	if (isTouchDevice() && cachedWindowWidth >= breakpoints.nav) {
		var mainNavTopLevelLinks = $(
			".site-header .main .navigation #menu-primary > .menu-item > a"
		);
		mainNavTopLevelLinks.click(function (e) {
			mainNavTopLevelLinks.not(this).removeClass("clicked");
			$(this).toggleClass("clicked");
			if ($(this).hasClass("clicked")) {
				e.preventDefault();
			}
		});
	}

	/* Mobile Navigation */
	$(".mobile-nav-btn, .mobile-nav-overlay").on("click", function () {
		$("body").toggleClass("show-menu");
		$(".mobile-nav-btn").toggleClass("is-open");
		$(".menu-item-has-children").removeClass("show-sub-menu");
	});

	// Sub menus
	$(".site-header .main .navigation .menu-item-has-children > a").on(
		"click",
		function (e) {
			if ($(window).width() < breakpoints.nav) {
				e.stopPropagation();
				e.preventDefault();

				var parentMenu = $(this)[0].outerHTML;

				$(this).parent().toggleClass("show-sub-menu");

				if (!$(this).parent().hasClass("has-back-btn")) {
					$(this)
						.next(".sub-menu")
						.prepend(
							'<li class="sub-menu-parent">' +
								parentMenu +
								"</li>"
						)
						.prepend(
							'<li class="sub-menu-back"><a href="#">Back</a></li>'
						);
					$(this).parent().addClass("has-back-btn");
				}
			}
		}
	);

	// Click function for inserted back button
	$(".site-header .main .navigation .sub-menu").on(
		"click",
		"> .sub-menu-back",
		function (e) {
			e.preventDefault();
			$(this).parent().parent().toggleClass("show-sub-menu");
		}
	);

	/* Popups */
	// Image popup
	$(".open-image-link").magnificPopup({
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		type: "image"
	});

	// Video popup
	$(".open-video-link").magnificPopup({
		// closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		preloader: false,
		removalDelay: 100,
		type: "iframe"
	});

	// Image Gallery popup
	$(".open-image-gallery-link").each(function () {
		$(this).magnificPopup({
			callbacks: {
				buildControls: function () {
					if (this.items.length > 1) {
						this.contentContainer.append(
							this.arrowLeft.add(this.arrowRight)
						);
					}
				}
			},
			closeBtnInside: true,
			closeMarkup:
				'<span class="icon-close mfp-close" title="Close"></span>',
			delegate: ".gallery-image-link",
			type: "image",
			gallery: {
				arrowMarkup: '<span class="icon-chevron-%dir% icon"></span>',
				enabled: true,
				tCounter: "",
				tPrev: "Previous",
				tNext: "Next"
			},
			image: {
				titleSrc: function (item) {
					var caption = item.el.attr("title");

					if (caption.length) {
						return '<div class="caption">' + caption + "</div>";
					} else {
						return "";
					}
				},
				verticalFit: true
			}
		});
	});

	// Need Help? Ask Kai! popup
	$(".need-help-ask-kai-form-link").magnificPopup({
		callbacks: {
			open: function () {
				// Add class to body and mfp-container element for css
				var popup = $(".need-help-ask-kai-popup");

				if (popup.length) {
					$("body").addClass("help-popup-active");
					popup.parent().parent().addClass("mfp-need-help-ask-kai");
				}
			},
			close: function () {
				// Remove class from body
				$("body").removeClass("help-popup-active");
			}
		},
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		items: {
			src: ".need-help-ask-kai-popup"
		},
		mainClass: "mfp-fade",
		removalDelay: 200,
		type: "inline"
	});

	// Schedule Free Consultation popup
	$(".schedule-free-consultation-form-link").magnificPopup({
		callbacks: {
			open: function () {
				// Add class to mfp-container element for css
				var popup = $(".schedule-free-consultation-popup");

				if (popup.length) {
					popup
						.parent()
						.parent()
						.addClass("mfp-schedule-free-consultation");
				}
			}
		},
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		items: {
			src: ".schedule-free-consultation-popup"
		},
		mainClass: "mfp-fade",
		removalDelay: 200,
		type: "inline"
	});

	// Testimonial popup (ajax)
	setTimeout(function () {
		$(".open-testimonial-link").magnificPopup({
			callbacks: {
				open: function () {
					setTimeout(function () {
						// Add class to mfp-container element for css
						var testimonialPopup = $(".testimonial-popup");

						if (testimonialPopup.length) {
							testimonialPopup
								.parent()
								.parent()
								.addClass("mfp-testimonial");
						}
					}, 1000);
				},
				parseAjax: function (mfpResponse) {
					mfpResponse.data = $(mfpResponse.data).find("#testimonial");
				}
			},
			closeBtnInside: true,
			closeMarkup:
				'<span class="icon-close mfp-close" title="Close"></span>',
			mainClass: "mfp-fade",
			preloader: false,
			removalDelay: 200,
			type: "ajax"
		});
	}, 100);

	// Testimonial popup (inline)
	setTimeout(function () {
		$(".open-testimonial-link-inline").on("click", function () {
			var testimonialPopupContent = $(this)
				.closest(".testimonial")
				.html();

			$.magnificPopup.open({
				callbacks: {
					// beforeOpen: function() {
					//   console.log(this.st.el.closest('.pestpac-review'));
					// },
					open: function () {
						setTimeout(function () {
							// Add class to mfp-container element for css
							var testimonialPopup = $(".testimonial-popup");

							if (testimonialPopup.length) {
								testimonialPopup
									.parent()
									.parent()
									.addClass("mfp-testimonial");
							}
						}, 1000);
					}
				},
				closeBtnInside: true,
				closeMarkup:
					'<span class="icon-close mfp-close" title="Close"></span>',
				items: {
					src:
						'<div class="testimonial-popup popup row"><div class="testimonial col-xs-12">' +
						testimonialPopupContent +
						"</div></div>",
					type: "inline"
				}
			});
		});
	}, 4100);

	/* Sliders */
	// Gallery slider
	// function gallerySlider() {
	//   $('.gallery-slider.acf-block .slides').each(function() {
	//     $(this).slick({
	//       appendArrows: $('.gallery-slider.acf-block .slider-controls > .arrows'),
	//       appendDots: $('.gallery-slider.acf-block .slider-controls > .dots'),
	//       arrows: false,
	//       // autoplay: true,
	//       autoplaySpeed: 5000,
	//       dots: true,
	//       // fade: true,
	//       infinite: true,
	//       mobileFirst: true,
	//       nextArrow: '<span class="icon-chevron-right icon" title="Next"></span>',
	//       prevArrow: '<span class="icon-chevron-left icon" title="Previous"></span>',
	//       responsive: [
	//         {
	//           breakpoint: breakpoints.small - 1,
	//           settings: {
	//             arrows: true,
	//             // dots: false,
	//           }
	//         }
	//       ],
	//       speed: 400
	//     });
	//   });
	// }
	// if ($('.gallery-slider.acf-block').length) {
	//   gallerySlider();
	// }

	// Testimonial slider
	function testimonialsSlider() {
		// Build slider
		$(".testimonials-slider .slides").slick({
			adaptiveHeight: true,
			appendArrows: $(
				".testimonials.acf-block .slider-controls > .arrows"
			),
			// appendDots: $('.testimonials.acf-block .slider-controls > .dots'),
			arrows: true,
			centerMode: true,
			centerPadding: "0px",
			// dots: true,
			infinite: true,
			mobileFirst: true,
			nextArrow:
				'<span class="icon-arrow-right icon" title="Next"></span>',
			prevArrow:
				'<span class="icon-arrow-left icon" title="Previous"></span>',
			responsive: [
				{
					breakpoint: breakpoints.medium - 1,
					settings: {
						adaptiveHeight: false
					}
				}
			],
			// slidesToScroll: 1,
			// slidesToShow: 1,
			speed: 500
		});
	}
	if ($(".testimonials-slider").length) {
		testimonialsSlider();
	}

	// Testimonials
	// Remove empty p tags
	$(".testimonial p:empty").remove();

	// List or single
	if ($(".testimonials-list").length || $(".single-testimonial").length) {
		// Remove excerpt in testimonial list
		$(".testimonial.no-excerpt .review .excerpt").remove();
	}

	// Slider
	if ($(".testimonials-slider").length) {
		$(".testimonial").each(function () {
			var testimonialSliderContent = $(this).find(".content");
			var testimonialSliderExcerpt = $(this).find(".excerpt > p");
			var testimonialSliderExcerptReadMore = $(this).find(
				".excerpt > p.read-more"
			);
			var testimonialPermalink = $(this).data("permalink");

			// If excerpt exists remove content div
			if (testimonialSliderExcerpt.length) {
				// Remove content
				testimonialSliderContent.remove();

				// Append Permalink
				if (testimonialSliderExcerptReadMore.length) {
					testimonialSliderExcerpt.append(
						'<a href="' +
							testimonialPermalink +
							'" class="open-testimonial-link" title="">Read More</a>'
					);
				}
			}
		});
	}

	// FAQ
	function faqAnswerToggle() {
		var faqQuestions = $(".faq-wrapper .item .question-wrapper");

		faqQuestions.click(function (e) {
			// If answer already open, close it
			if ($(this).hasClass("open")) {
				$(this).removeClass("open");

				// Otherwise...
			} else {
				// Close all answers
				// faqQuestions.removeClass('open');

				// Open this questions answer only
				$(this).addClass("open");
			}
		});
	}
	if ($(".faq.acf-block").length) {
		faqAnswerToggle();
	}

	// Services pricing
	function maintenancePlans() {
		// Money formatter
		var formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			maximumFractionDigits: 0
		});

		function setPlans() {
			// Loop through plans
			$(".plans .plan").each(function () {
				// Variables
				var $this = $(this);
				var annualMonthlySwitch = $(".annual-monthly-switch");

				// Set pricing function
				function setPricing($this) {
					var pricingType = $this
						.find(".pricing")
						.data("pricing-type");
					var annualPricingToggle = $this.find(
						".pricing .btn.annual"
					);
					var monthlyPricingToggle = $this.find(
						".pricing .btn.monthly"
					);
					var annualPricing =
						annualPricingToggle.data("annual-pricing");
					var monthlyPricing =
						monthlyPricingToggle.data("monthly-pricing");

					// Determine which plan we are on and assign multipliers
					// Plan 1
					if ($this.hasClass("plan-1")) {
						var annualMP =
							$(".type-switch").attr("data-p1-annual-mp");
						var monthlyMP =
							$(".type-switch").attr("data-p1-monthly-mp");
						// Plan 2
					} else if ($this.hasClass("plan-2")) {
						var annualMP =
							$(".type-switch").attr("data-p2-annual-mp");
						var monthlyMP =
							$(".type-switch").attr("data-p2-monthly-mp");
						// Plan 3
					} else if ($this.hasClass("plan-3")) {
						var annualMP =
							$(".type-switch").attr("data-p3-annual-mp");
						var monthlyMP =
							$(".type-switch").attr("data-p3-monthly-mp");
					} else {
						var annualMP = 1;
						var monthlyMP = 1;
					}

					// Annual savings
					var annualSavingsPriceEl = $this.find(
						".pricing .breakdown .savings .price"
					);

					annualSavingsPriceEl.text(
						formatter.format(
							(monthlyPricing / 12 - annualPricing / 12) *
								12 *
								annualMP
						)
					);

					// Monthly cost
					var monthlyCostPriceEl = $this.find(
						".pricing .breakdown .monthly-cost .price"
					);
					console.log(monthlyMP);
					if (pricingType == "annual") {
						monthlyCostPriceEl.text(
							formatter.format((annualPricing / 12) * monthlyMP)
						);
					} else {
						monthlyCostPriceEl.text(
							formatter.format((monthlyPricing / 12) * monthlyMP)
						);
					}

					// Annual cost
					var annualCostPriceEl = $this.find(
						".pricing .breakdown .annual-cost .price"
					);
					if (pricingType == "annual") {
						annualCostPriceEl.text(
							formatter.format(annualPricing * annualMP)
						);
					} else {
						annualCostPriceEl.text(
							formatter.format(monthlyPricing * annualMP)
						);
					}
				}
				// Initial pricing
				setPricing($this);

				// Loop through buttons
				annualMonthlySwitch.on("click", ".btn", function () {
					var pricingEl = $(this).closest(".pricing");

					if (!$(this).hasClass("active")) {
						// Add active class if it doesn't already exist, remove active class from sibling
						$(this)
							.addClass("active")
							.siblings()
							.removeClass("active");

						// Set pricing type pricing element so we can use later to adjust pricing
						pricingEl.data(
							"pricing-type",
							$(this).data("pricing-type")
						);
						pricingEl.attr(
							"data-pricing-type",
							$(this).data("pricing-type")
						);

						// Update pricing
						setPricing($(this).closest(".plan"));
					}
				});
			});
		}
		// Initial plans
		setPlans();

		// Handle spa type toggle switch
		var spaTypeSwitch = $(".type-switch");

		// Loop through buttons
		spaTypeSwitch.on("click", ".btn", function () {
			if (!$(this).hasClass("active")) {
				// Add active class if it doesn't already exist, remove active class from sibling
				$(this).addClass("active").siblings().removeClass("active");

				// Set spa pricing modifier and type on parent toggle element so we can use later to adjust pricing
				spaTypeSwitch.data(
					"data-p1-annual-mp",
					$(this).data("p1-annual-mp")
				);
				spaTypeSwitch.attr(
					"data-p1-annual-mp",
					$(this).data("p1-annual-mp")
				);
				spaTypeSwitch.data(
					"data-p1-monthly-mp",
					$(this).data("p1-monthly-mp")
				);
				spaTypeSwitch.attr(
					"data-p1-monthly-mp",
					$(this).data("p1-monthly-mp")
				);
				spaTypeSwitch.data(
					"data-p2-annual-mp",
					$(this).data("p2-annual-mp")
				);
				spaTypeSwitch.attr(
					"data-p2-annual-mp",
					$(this).data("p2-annual-mp")
				);
				spaTypeSwitch.data(
					"data-p2-monthly-mp",
					$(this).data("p2-monthly-mp")
				);
				spaTypeSwitch.attr(
					"data-p2-monthly-mp",
					$(this).data("p2-monthly-mp")
				);
				spaTypeSwitch.data(
					"data-p3-annual-mp",
					$(this).data("p3-annual-mp")
				);
				spaTypeSwitch.attr(
					"data-p3-annual-mp",
					$(this).data("p3-annual-mp")
				);
				spaTypeSwitch.data(
					"data-p3-monthly-mp",
					$(this).data("p3-monthly-mp")
				);
				spaTypeSwitch.attr(
					"data-p3-monthly-mp",
					$(this).data("p3-monthly-mp")
				);
				spaTypeSwitch.data("type", $(this).data("type"));
				spaTypeSwitch.attr("data-type", $(this).data("type"));
			}

			setPlans();
		});
	}
	if ($(".maintenance-plans.acf-block").length) {
		maintenancePlans();
	}

	// Need Help? Ask Kai!
	function needHelpAskKai() {
		// Variables
		var needHelpClosedCookie = Cookies.get("need-help-closed");
		var needHelpEl = $(".need-help-ask-kai");
		var needHelpCloseButtonEl = $(".need-help-ask-kai .icon-close");

		// Hide if cookie set
		if (needHelpClosedCookie == "true") {
			needHelpEl.hide();
		}

		// Handle close button
		needHelpCloseButtonEl.click(function () {
			// Hide Need Help element
			needHelpEl.hide();

			// Set cookie so it remains closed for 1 hour
			Cookies.set("need-help-closed", "true", { expires: 0.05 });
		});
	}
	if ($(".need-help-ask-kai").length) {
		needHelpAskKai();
	}

	// Vimeo API
	function fadeOutBannerImage() {
		var video = $(".banner.has-background-video iframe.background-video");
		var videoPoster = $(
			".banner.has-background-video .background-video-poster"
		);
		var player = new Vimeo.Player(video);

		// Fade out video poster when the video starts playing
		player.on("play", function () {
			videoPoster.fadeOut(1000);
		});
	}
	if ($(".banner.has-background-video").length) {
		fadeOutBannerImage();
	}
})(jQuery);
